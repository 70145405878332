<template>
  <div id="app">
    <div class="background" :style="{'background-image': 'url(' + require('./assets/tuune.jpg') + ')'}"></div>
    <div class="details" style="">
      <div class="info">
        <img :src="require('./assets/tuune2.jpg')" style="width: 100%">
        <p class="title">TUUNE PAULER</p>

        <p style="margin-top: 8px; margin-bottom: 8px;">
          The work of Tuune Pauler is mostly made of heavily processed vocals, broken rhythms consisting of samples from organic sources and a trembling low end which all from time-to-time unravel into beautiful shoegaze-ish walls of sound. 
          <br><br>His debut EP was released by Svart Records in 2022.
        </p><br>
        <iframe style="border-radius:12px" src="https://open.spotify.com/embed/album/6p9SsgEITFUkNpwcdY82uo?utm_source=generator&theme=0" width="100%" height="180" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
        <iframe style="border-radius:12px" src="https://open.spotify.com/embed/album/2EDsp8coMc3SVusSpqAH9O?utm_source=generator&theme=0" width="100%" height="180" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
        <iframe style="border-radius:12px" src="https://open.spotify.com/embed/album/2vvm7vv2rm8etmj5gYEczw?utm_source=generator&theme=0" width="100%" height="180" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>

        <p>PAST GIGS:</p>
        <p style="font-size: 12px;margin-top: 8px;">
          23.8.2024 BLUE SEA FILM FESTIVAL, RAUMA, FINLAND<br>
          26.5.2023 TRAME FESTIVAL, GROSSETO, ITALY<br>
          22.4.2023 KONTULA ELECTRONIC, HELSINKI, FINLAND<br>
          28.7.2022 RAUMA ART MUSEUM, RAUMA, FINLAND<br>
          21.5.2022 POROHOLMAN RANTATALO, RAUMA, FINLAND<br>
          16.4.2022 (RAUMA ELECTRONIC + VOKOLOGI) - BRUMMI, RAUMA, FINLAND<br><br>
        </p>

        CONTACT:<br>
        <a href="https://www.instagram.com/tuunepauler/">Instagram</a><br>
        <a href="mailto:tuunepauler@gmail.com">tuunepauler@gmail.com</a><br><br>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
   
  }
}
</script>

<style>
body {
  background: #111;
}

#app {
  font-family: 'Kufam', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  display: flex;
  font-size: 16px;
  justify-content: end;
  color: #fff;
  padding-top: 32px;
  padding-bottom: 32px;
  min-height: 100vh;
}

#app .details {
  position: relative;
  z-index: 2;
  max-width: 530px; width: 90%; margin-right: 5%; margin-left: 5%;
}

.background {
  position: fixed;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  background-size: cover;
  z-index: 1;
  animation: shake 30s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg) scale(1.1); }
  10% { transform: translate(-1px, -2px) rotate(-1deg) scale(1.1); }
  20% { transform: translate(-3px, 0px) rotate(1deg) scale(1.1); }
  30% { transform: translate(3px, 2px) rotate(0deg) scale(1.1); }
  40% { transform: translate(1px, -1px) rotate(1deg) scale(1.2); }
  50% { transform: translate(-1px, 2px) rotate(-1deg) scale(1.4); }
  60% { transform: translate(-3px, 1px) rotate(0deg) scale(1.2); }
  70% { transform: translate(3px, 1px) rotate(-1deg) scale(1.1); }
  80% { transform: translate(-1px, -1px) rotate(1deg) scale(1.1); }
  90% { transform: translate(1px, 2px) rotate(-1deg) scale(1.1); }
  100% { transform: translate(1px, 1px) rotate(0deg) scale(1.1); }
}

.info {
  text-align: left;
  border-radius: 8px;
  background-color: rgb(0 0 0 / 85%);
  padding: 48px;
  margin-bottom: 16px;
}


p {
  margin-top: 0;
  margin-bottom: 0;
}

p.title {
  font-size: 22px;
  margin-top: 16px;
  margin-bottom: 16px;
}

a {
  color: #FFF !important;
}
</style>
